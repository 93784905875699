<template>
  <div>
    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1200">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Attendance Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Dates-->
                <v-col cols="12" sm="12" md="6">
                  <text-display
                    v-if="attendanceItemsStore.entry.attendance"
                    :displayLabel="'Attendance Date'"
                    :displayString="
                      $_monthNumToText(attendanceItemsStore.entry.attendance.f_month) +
                        ' - ' +
                        attendanceItemsStore.entry.attendance.d_year
                    "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Provider-->
                <v-col cols="12" sm="12" md="8">
                  <text-display
                    v-if="
                      attendanceItemsStore.entry.attendance &&
                        attendanceItemsStore.entry.attendance.provider
                    "
                    :displayLabel="'Provider'"
                    :displayString="
                      attendanceItemsStore.entry.attendance.provider.d_licenseNumber +
                        ' - ' +
                        attendanceItemsStore.entry.attendance.provider.d_nameLicense
                    "
                  />
                </v-col>

                <!--Provider Classroom-->
                <v-col
                  v-if="
                    attendanceItemsStore.entry.care_plan_item &&
                      attendanceItemsStore.entry.care_plan_item.care_plan &&
                      attendanceItemsStore.entry.care_plan_item.care_plan.classroom
                  "
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <text-display
                    :displayLabel="'Classroom'"
                    :displayString="
                      attendanceItemsStore.entry.care_plan_item.care_plan.classroom.d_name
                    "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Client-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    v-if="attendanceItemsStore.entry.client"
                    :displayLabel="'Client ID'"
                    :displayNumber="attendanceItemsStore.entry.client.id"
                  />
                </v-col>

                <!--Client PreK Seat ID-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    v-if="attendanceItemsStore.entry.client"
                    :displayLabel="'PreK Seat ID'"
                    :displayString="attendanceItemsStore.entry.client.d_prekSeatID"
                  />
                </v-col>

                <!--Client-->
                <v-col cols="12" sm="12" md="4">
                  <text-display
                    v-if="attendanceItemsStore.entry.client"
                    :displayLabel="'Client'"
                    :displayString="attendanceItemsStore.entry.client.d_nameDisplay"
                  />
                </v-col>

                <!--Specialist-->
                <v-col cols="12" sm="12" md="4">
                  <text-display
                    v-if="
                      attendanceItemsStore.entry.client &&
                        attendanceItemsStore.entry.client.counselor
                    "
                    :displayLabel="'Specialist'"
                    :displayString="attendanceItemsStore.entry.client.counselor.name"
                  />
                </v-col>
              </v-row>

              <v-tabs class="pt-4" grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Attendance Details</v-tab>
                <v-tab>Care Plan Option Info</v-tab>

                <!--Attendance Details-->
                <v-tab-item>
                  <v-card flat height="650">
                    <v-row dense>
                      <!--Left Side-->
                      <v-col cols="12" sm="12" md="8">
                        <v-container>
                          <v-row dense>
                            <!--Certification Start Date-->
                            <v-col cols="12" sm="6" md="3">
                              <text-display
                                v-if="attendanceItemsStore.entry.care_plan_item"
                                :displayLabel="'Certification Start Date'"
                                :displayString="
                                  $_formatDate(
                                    attendanceItemsStore.entry.care_plan_item.care_plan.d_dateStart
                                  )
                                "
                              />
                            </v-col>

                            <!--Certification End Date-->
                            <v-col cols="12" sm="6" md="3">
                              <text-display
                                v-if="attendanceItemsStore.entry.care_plan_item"
                                :displayLabel="'Certification End Date'"
                                :displayString="
                                  $_formatDate(
                                    attendanceItemsStore.entry.care_plan_item.care_plan.d_dateEnd
                                  )
                                "
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <!--Certification Start Date-->
                            <v-col cols="12" sm="6" md="3">
                              <text-display
                                v-if="attendanceItemsStore.entry.care_plan_item"
                                :displayLabel="'Start Date'"
                                :displayString="
                                  $_formatDate(
                                    attendanceItemsStore.entry.care_plan_item.d_dateStart
                                  )
                                "
                              />
                            </v-col>

                            <!--Care Plan Item Eligibility Check-In Date-->
                            <v-col cols="12" sm="6" md="3">
                              <text-display
                                v-if="attendanceItemsStore.entry.care_plan_item"
                                :displayLabel="'Eligibility Check-In Date'"
                                :displayString="
                                  $_formatDate(attendanceItemsStore.entry.care_plan_item.d_dateEnd)
                                "
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <!--Actual Start Date-->
                            <v-col cols="12" sm="6" md="3">
                              <v-menu
                                :close-on-content-click="false"
                                v-model="menu3"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template #activator="{ on }">
                                  <v-text-field
                                    v-on="on"
                                    v-model="d_dateStartOverride"
                                    label="Actual Start"
                                    placeholder=" "
                                    persistent-placeholder
                                    prepend-icon="fal fa-calendar-alt"
                                    required
                                    @blur="date3 = $_parseDate(d_dateStartOverride)"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="date3"
                                  @input="menu3 = false"
                                  color="primary"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>

                            <!--Actual End Date-->
                            <v-col cols="12" sm="6" md="3">
                              <v-menu
                                :close-on-content-click="false"
                                v-model="menu4"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template #activator="{ on }">
                                  <v-text-field
                                    v-on="on"
                                    v-model="d_dateEndOverride"
                                    label="Actual End"
                                    placeholder=" "
                                    persistent-placeholder
                                    prepend-icon="fal fa-calendar-alt"
                                    required
                                    @blur="date4 = $_parseDate(d_dateEndOverride)"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="date4"
                                  @input="menu4 = false"
                                  color="primary"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="d_daysPresent"
                                label="Days Present"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>

                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="d_daysAbsent"
                                label="Days Absent"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>

                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="d_daysClosed"
                                label="Days Closed"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>

                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="d_daysTotal"
                                label="Total Days"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>

                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="d_daysProrated"
                                label="Prorated Days"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>

                            <v-col cols="12" sm="12" md="2">
                              <text-display
                                v-if="d_daysProrated"
                                :displayLabel="'Percentage'"
                                :displayNumber="proratedAmount"
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12">
                              <v-textarea
                                rows="2"
                                v-model="d_reasonAbsent"
                                label="Reason Absent"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="3">
                              <text-display
                                v-if="attendanceItemsStore.entry.care_plan_item"
                                :displayLabel="'Parent Fee'"
                                :displayString="
                                  $_formatMoney(
                                    attendanceItemsStore.entry.care_plan_item.d_parentFee
                                  )
                                "
                              />
                            </v-col>

                            <v-col cols="12" sm="3">
                              <span class="grey--text text--darken-1 text-caption pb-0 mb-0"
                                >Parent Fee Not Collected</span
                              >
                              <v-checkbox
                                v-model="d_parentFeeNotCollected"
                                label=""
                                value="Yes"
                                color="primary"
                                class="pr-2"
                                hide-details
                              >
                              </v-checkbox>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <!--Follow Up Reason-->
                            <v-col cols="12" sm="12">
                              <v-autocomplete
                                :items="valueListsStore.valueListItems[10]"
                                label="Follow Up Reason"
                                placeholder=" "
                                persistent-placeholder
                                item-text="d_name"
                                item-value="id"
                                v-model="f_followup"
                                clearable
                                clear-icon="fal fa-times-circle"
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <!--Status-->
                            <v-col cols="12" sm="6">
                              <v-select
                                v-if="usersStore.authUser.f_attendanceStatus === 1"
                                :items="valueListsStore.attendanceItemStatus"
                                item-text="value"
                                item-value="id"
                                label="Status"
                                placeholder=" "
                                persistent-placeholder
                                v-model="f_status"
                                clearable
                                clear-icon="fal fa-times-circle"
                              />
                              <text-display
                                v-else
                                :displayLabel="'Status'"
                                :displayString="$_attendanceStatus(f_status)"
                              />
                            </v-col>

                            <!--Approved Timestamp-->
                            <v-col v-if="f_status === 3 && d_dateApproved" cols="12" sm="6">
                              <text-display
                                :displayLabel="'Date Approved'"
                                :displayString="$_formatTimestamp(d_dateApproved)"
                              />
                            </v-col>
                          </v-row>

                          <v-row class="pb-4">
                            <v-col cols="12" sm="12">
                              <span class="text-caption grey--text"
                                >Created: {{ $_formatTimestamp(created_at) }} &#8226; Modified:
                                {{ $_formatTimestamp(updated_at) }}</span
                              >
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>

                      <v-divider vertical />

                      <!--Right Side-->
                      <v-col cols="12" sm="12" md="3">
                        <v-container>
                          <v-row dense>
                            <!--Internal Note-->
                            <v-col cols="12" sm="12">
                              <v-textarea
                                v-model="d_noteInternal"
                                label="Internal Note"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <!--External Note-->
                            <v-col cols="12" sm="12">
                              <v-textarea
                                v-model="d_noteExternal"
                                label="External Note"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <!--Care Plan Item Info-->
                <v-tab-item>
                  <v-card flat height="650">
                    <v-row dense>
                      <!--Eligibility Reason-->
                      <v-col cols="12" sm="12">
                        <text-display
                          v-if="
                            attendanceItemsStore.entry.care_plan_item &&
                              attendanceItemsStore.entry.care_plan_item.care_plan
                          "
                          :displayLabel="'Eligibilty Reason'"
                          :displayString="
                            attendanceItemsStore.entry.care_plan_item.care_plan.d_eligibilityReason
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Program-->
                      <v-col cols="12" sm="12" md="6">
                        <text-display
                          v-if="
                            attendanceItemsStore.entry.care_plan_item &&
                              attendanceItemsStore.entry.care_plan_item.program
                          "
                          :displayLabel="'Program'"
                          :displayString="attendanceItemsStore.entry.care_plan_item.program.d_name"
                        />
                      </v-col>

                      <!--Program Option-->
                      <v-col cols="12" sm="12" md="6">
                        <text-display
                          v-if="
                            attendanceItemsStore.entry.care_plan_item &&
                              attendanceItemsStore.entry.care_plan_item.program_option
                          "
                          :displayLabel="'Program Option'"
                          :displayString="
                            attendanceItemsStore.entry.care_plan_item.program_option.d_name
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Time-->
                      <v-col cols="12" sm="12">
                        <text-display
                          v-if="
                            attendanceItemsStore.entry.care_plan_item &&
                              attendanceItemsStore.entry.care_plan_item.time_option
                          "
                          :displayLabel="'Time'"
                          :displayString="
                            attendanceItemsStore.entry.care_plan_item.time_option.d_name
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Income-->
                      <v-col cols="12" sm="12">
                        <text-display
                          v-if="attendanceItemsStore.entry.care_plan_item"
                          :displayLabel="'Income'"
                          :displayString="
                            $_formatMoney(attendanceItemsStore.entry.care_plan_item.d_income)
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--SMI-->
                      <v-col cols="12" sm="12" md="3">
                        <text-display
                          v-if="attendanceItemsStore.entry.care_plan_item"
                          :displayLabel="'FPL'"
                          :displayString="
                            $_formatPercentage(attendanceItemsStore.entry.care_plan_item.d_fpl)
                          "
                        />
                      </v-col>
                      <!--SMI-->
                      <v-col cols="12" sm="12" md="3">
                        <text-display
                          v-if="attendanceItemsStore.entry.care_plan_item"
                          :displayLabel="'SMI'"
                          :displayString="
                            $_formatPercentage(attendanceItemsStore.entry.care_plan_item.d_smi)
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Placeholder-->
                      <v-col cols="12" sm="12" md="3">
                        <text-display
                          v-if="
                            attendanceItemsStore.entry.care_plan_item &&
                              attendanceItemsStore.entry.care_plan_item.care_plan
                          "
                          :displayLabel="'Placeholder'"
                          :displayString="
                            $_yesNo(
                              attendanceItemsStore.entry.care_plan_item.care_plan.f_placeholder
                            )
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "attendanceItemsStore/getEntryField",
  mutationType: "attendanceItemsStore/updateEntryField"
});

export default {
  name: "AttendanceItemEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      attendanceItemsStore: state => state.attendanceItemsStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "show2",
      "valid",
      "mode",
      "id",
      "tab",

      "fk_attendanceID",
      "fk_clientID",
      "d_dateStartCarePlan",
      "d_dateEndCarePlan",
      "d_dateStartOverride",
      "d_dateEndOverride",
      "d_daysPresent",
      "d_daysAbsent",
      "d_daysClosed",
      "d_daysTotal",
      "d_daysProrated",
      "d_noteExternal",
      "d_noteInternal",
      "d_parentFeeNotCollected",
      "d_parentFee",
      "d_reasonAbsent",
      "d_dateApproved",
      "f_followup",
      "f_status",
      "header",
      "date1",
      "date2",
      "date3",
      "date4",
      "created_at",
      "updated_at"
    ]),
    proratedAmount() {
      const proratedDays = Math.round((21.67 / this.d_daysTotal) * 10000) / 10000;
      const proratedAmount = Math.round(proratedDays * this.d_daysProrated * 10000) / 10000;
      return proratedAmount.toFixed(4);
    }
  },
  components: {
    TextDisplay
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false
    };
  },
  watch: {
    date1() {
      this.d_dateStartCarePlan = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateEndCarePlan = this.$_formatDate(this.date2);
    },
    date3() {
      this.d_dateStartOverride = this.$_formatDate(this.date3);
    },
    date4() {
      this.d_dateEndOverride = this.$_formatDate(this.date4);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("attendanceItemsStore/resetEntry");
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["attendanceItemsStore/getEntry"];
        await this.$store.dispatch("attendanceItemsStore/update", entry);
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
