<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="attendanceSection === 1" :title-value="'Attendance'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-clipboard-list-check</v-icon>
        <v-toolbar-title class="pl-2">Attendance</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="700px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row v-if="attendanceSection !== 2" dense>
                    <!--Provider License-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_licenseNumber"
                        label="Provider License #"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="attendanceSection !== 2" dense>
                    <!--Provider Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLicense"
                        label="Provider Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Household ID-->
                    <v-col v-if="attendanceSection !== 3" cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="fk_householdID"
                        label="Household ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>

                    <!--PreK Seat ID-->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="d_prekSeatID"
                        label="PreK Seat ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="attendanceSection !== 3" dense>
                    <!--Client ID-->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="clientID"
                        label="Client ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>

                    <!--v1 Child ID-->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="d_childIDv1"
                        label="v1 Child ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="attendanceSection !== 3" dense>
                    <!--Client First Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameFirst"
                        label="Client First Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="attendanceSection !== 3" dense>
                    <!--Client Last Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLast"
                        label="Client Last Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Follow Up-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[10]"
                        item-text="d_name"
                        item-value="id"
                        label="Follow Up Reason"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_followup"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--No Follow Up-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >No Follow Up Required</label
                      >
                      <v-checkbox
                        v-model="noFollowup"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Placeholder-->
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        :items="valueListsStore.yesNo"
                        label="Placeholder"
                        placeholder=" "
                        persistent-placeholder
                        item-text="value"
                        item-value="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                        v-model="f_placeholder"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.attendanceItemStatus"
                        item-text="value"
                        item-value="id"
                        label="Attendance Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Attendance Approval Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu5"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateApproved"
                            label="Attendance Approval Date (>= date entered)"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date5 = $_parseDate(d_dateApproved)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date5"
                          @input="menu5 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Month-->
                    <v-col cols="12" sm="7">
                      <v-select
                        :items="valueListsStore.months"
                        item-text="value"
                        item-value="id"
                        label="Attendance Month"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_month"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Year-->
                    <v-col cols="12" sm="5">
                      <v-select
                        :items="yearsStore.yearsValueList"
                        item-text="d_yearStart"
                        item-value="d_yearStart"
                        label="Attendance Year"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_year"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Range Start Date-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu3"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="dateStart"
                            label="Range Start Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date3 = $_parseDateMonth(dateStart)"
                          />
                        </template>
                        <v-date-picker
                          type="month"
                          v-model="date3"
                          @input="menu3 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!--Range End Date-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu4"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="dateEnd"
                            label="Range End Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date4 = $_parseDateMonth(dateEnd)"
                          />
                        </template>
                        <v-date-picker
                          type="month"
                          v-model="date4"
                          @input="menu4 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Start Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateStart"
                            label="Care Plan Option Start Date (>= date entered)"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date1 = $_parseDate(d_dateStart)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date1"
                          @input="menu = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Eligibility Check In Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu2"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateEnd"
                            label="Eligibility Check In Date (<= date entered)"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date2 = $_parseDate(d_dateEnd)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date2"
                          @input="menu2 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Program-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.programsValueList"
                        label="Program"
                        placeholder=" "
                        persistent-placeholder
                        item-text="d_name"
                        item-value="id"
                        v-model="fk_programID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullProgramOptions(fk_programID)"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      (fk_programID && programsStore.optionsValueList.length > 0) ||
                        (fk_programID && fk_programOptionID)
                    "
                  >
                    <!--Program Options-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.optionsValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Program Option"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_programOptionID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-else dense>
                    <text-display
                      :displayLabel="'Program Option'"
                      :displayString="'Select Program First'"
                    />
                  </v-row>

                  <v-row dense>
                    <!--Days Absent-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_daysAbsent"
                        label="Days Absent (>= will be used)"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Days Total-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_daysTotal"
                        label="Days Total (<= will be used)"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Attendance Entered-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12" md="6">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >Attendance Entered</label
                      >
                      <v-checkbox
                        v-model="attendanceEntered"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>

                    <!--No Attendance Entered-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12" md="6">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >No Attendance Entered</label
                      >
                      <v-checkbox
                        v-model="attendanceNotEntered"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Actual Start Dates Entered-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12" md="6">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >Actual Start Date Provided</label
                      >
                      <v-checkbox
                        v-model="actualDatesStartEntered"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                    <!--Actual End Dates Entered-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12" md="6">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >Actual End Date Provided</label
                      >
                      <v-checkbox
                        v-model="actualDatesEndEntered"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <span class="grey--text text--darken-1 text-caption pb-0 mb-0"
                        >Parent Fee Not Collected</span
                      >
                      <v-checkbox
                        v-model="parentFeeNotCollected"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <attendance-item-entry />

      <!--Report Date Picker Dialog-->
      <report-date-picker
        :datePickerSection="attendanceSection === 2 ? 1 : 0"
        :enabled="
          attendanceItemsStore.attendanceItems.data &&
            attendanceItemsStore.attendanceItems.data.length > 0
        "
      />

      <export-excel
        :section="19"
        :enabled="
          attendanceItemsStore.attendanceItems.data &&
            attendanceItemsStore.attendanceItems.data.length > 0
        "
      />

      <attendance-item-mass-change />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="
        attendanceItemsStore.attendanceItems.data &&
          attendanceItemsStore.attendanceItems.data.length > 0
      "
      :showTotal="true"
      :currentPage="attendanceItemsStore.attendanceItems.current_page"
      :lastPage="attendanceItemsStore.attendanceItems.last_page"
      :total="attendanceItemsStore.attendanceItems.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="
          attendanceItemsStore.attendanceItems.data &&
            attendanceItemsStore.attendanceItems.data.length > 0
        "
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">Client ID</th>
              <th class="text-left grey--text text--darken-1">PreK Seat ID</th>
              <th class="text-left grey--text text--darken-1">Client</th>
              <th class="text-left grey--text text--darken-1">Start Date</th>
              <th class="text-left grey--text text--darken-1">Eligibility<br />Check-In Date</th>
              <th class="text-left grey--text text--darken-1">Actual Start</th>
              <th class="text-left grey--text text--darken-1">Actual End</th>
              <th class="text-left grey--text text--darken-1">Option</th>
              <th class="text-left grey--text text--darken-1">Time</th>
              <th class="text-left grey--text text--darken-1">Days<br />Present</th>
              <th class="text-left grey--text text--darken-1">Days<br />Absent</th>
              <th class="text-left grey--text text--darken-1">Days<br />Closed</th>
              <th class="text-left grey--text text--darken-1">Total<br />Days</th>
              <th class="text-left grey--text text--darken-1">Prorated<br />Days</th>
              <th class="text-left grey--text text--darken-1">Follow Up</th>
              <th class="text-right grey--text text--darken-1">Status</th>
              <th>
                <v-btn rounded @click="selectDeselectAll()" color="primary" class="py-0"
                  ><v-icon small left>fal fa-check-double</v-icon>All</v-btn
                >
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in attendanceItemsStore.attendanceItems.data" :key="item.id">
            <tr v-if="item.header">
              <th class="text-subtitle-2 text-left grey white--text" colspan="18">
                <span class="pr-4">{{ item.header }}</span>
                <v-tooltip
                  v-if="attendanceSection === 1 || attendanceSection === 3"
                  top
                  color="primary"
                >
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="detail(1, item.attendance.provider.uuid)">
                      <v-icon color="white">
                        fal fa-arrow-alt-square-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Go To Provider</span>
                </v-tooltip>
              </th>
            </tr>
            <tr class="clickable">
              <!--1 - Client ID-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.client.id }}
              </td>
              <!--2 - PreK Seat ID-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.client.d_prekSeatID }}
              </td>
              <!--3 - Client-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.client.d_nameDisplay }}
              </td>
              <!--4 - Start Date-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ $_formatDate(item.care_plan_item.d_dateStart) }}
              </td>
              <!--5 - Eligibility Check-In Date-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ $_formatDate(item.care_plan_item.d_dateEnd) }}
              </td>
              <!--6 - Actual Start-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ $_formatDate(item.d_dateStartOverride) }}
              </td>
              <!--7 - Actual End-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ $_formatDate(item.d_dateEndOverride) }}
              </td>
              <!--8 - Option-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{
                  item.care_plan_item && item.care_plan_item.program_option
                    ? item.care_plan_item.program_option.d_name
                    : ""
                }}
              </td>
              <!--9 - Time-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{
                  item.care_plan_item && item.care_plan_item.time_option
                    ? item.care_plan_item.time_option.d_name
                    : ""
                }}
              </td>
              <!--10 - Days Present-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.d_daysPresent }}
              </td>
              <!--11 - Days Absent-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.d_daysAbsent }}
              </td>
              <!--12 - Days Closed-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.d_daysClosed }}
              </td>
              <!--13 - Days Total-->
              <td
                v-if="
                  checkTotalHighlight(
                    item.d_daysPresent,
                    item.d_daysAbsent,
                    item.d_daysClosed,
                    item.d_daysTotal
                  )
                "
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4 error"
              >
                {{ item.d_daysTotal }}
              </td>
              <!--13 - Days Closed-->
              <td
                v-else
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.d_daysTotal }}
              </td>
              <!--14 - Days Prorated-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ item.d_daysProrated }}
              </td>
              <!--15 - Follow Up-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                <v-icon v-if="item.f_followup" left medium>fal fa-comment-alt-check</v-icon>
              </td>
              <!--16 - Status-->
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-right light-blue--text text--darken-4"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                {{ $_attendanceStatus(item.f_status) }}
              </td>
              <!--17 - Go TO Detail-->
              <td
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                <v-layout align-center justify-space-around>
                  <v-checkbox
                    dense
                    :key="index"
                    v-model="selectedID"
                    :value="item.id"
                    color="primary"
                    multiple
                    class="py-2"
                  ></v-checkbox>
                  <v-tooltip
                    v-if="
                      (item.client.f_privateRecord === 0 && attendanceSection !== 3) ||
                        (usersStore.authUser.f_viewPrivateRecords === 1 &&
                          item.client.f_privateRecord === 1 &&
                          attendanceSection !== 3)
                    "
                    top
                    color="primary"
                  >
                    <template #activator="{ on }">
                      <v-btn icon v-on="on" @click="detail(2, item.client.uuid)">
                        <v-icon color="primary">
                          fal fa-arrow-alt-square-right
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Go To Client</span>
                  </v-tooltip>
                </v-layout>
              </td>
              <!--18 - Private Lock-->
              <td
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              >
                <v-chip v-if="item.client.f_privateRecord === 1" color="error"
                  ><v-icon small>fal fa-lock</v-icon></v-chip
                >
              </td>
            </tr>
            <tr class="clickable" dense v-if="item.d_reasonAbsent">
              <td
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
              ></td>
              <td
                @click="openEntry(item.id, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4 font-italic"
                :class="
                  item.care_plan_item.f_show === 0 || item.care_plan_item.f_status === 5
                    ? 'error'
                    : item.followup
                    ? item.followup.d_color
                    : ''
                "
                colspan="17"
              >
                {{ item.d_reasonAbsent }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Attendance Items Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import AttendanceItemMassChange from "@/components/AttendanceItemMassChange";
import ExportExcel from "@/components/ExportExcel";
import TextDisplay from "@/components/TextDisplay";
import AttendanceItemEntry from "@/components/AttendanceItemEntry";
import ReportDatePicker from "@/components/ReportDatePicker";

const { mapFields } = createHelpers({
  getterType: "attendanceItemsStore/getFilterField",
  mutationType: "attendanceItemsStore/updateFilterField"
});

export default {
  name: "AttendanceItems",
  components: {
    TitleBar,
    Pagination,
    AttendanceItemMassChange,
    ExportExcel,
    TextDisplay,
    AttendanceItemEntry,
    ReportDatePicker
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      attendanceItemsStore: state => state.attendanceItemsStore,
      yearsStore: state => state.yearsStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore,
      programsStore: state => state.programsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_providerID",
      "d_licenseNumber",
      "d_nameLicense",
      "d_childIDv1",
      "fk_clientID",
      "fk_householdID",
      "d_prekSeatID",
      "d_nameFirst",
      "d_nameLast",
      "f_month",
      "d_year",
      "f_followup",
      "f_status",
      "noFollowup",
      "selectedID",
      "d_dateStart",
      "d_dateEnd",
      "date1",
      "date2",
      "fk_programID",
      "fk_programOptionID",
      "clientID",
      "f_viewPrivateRecords",
      "dateStart",
      "dateEnd",
      "date3",
      "date4",
      "date5", //BG 6/21/2021 added date filter field for d_dateApproved
      "d_daysAbsent",
      "d_daysTotal",
      "attendanceEntered",
      "attendanceNotEntered",
      "f_placeholder",
      "fk_reportID",
      "actualDatesStartEntered",
      "actualDatesEndEntered",
      "parentFeeNotCollected",
      "d_dateApproved"
    ])
  },
  props: {
    /* All List = 1, Provider Sublist = 2, Client Sublist = 3 */
    attendanceSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      filterMenu: false
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateEnd = this.$_formatDate(this.date2);
    },
    date3() {
      this.dateStart = this.$_formatDateMonth(this.date3);
    },
    date4() {
      this.dateEnd = this.$_formatDateMonth(this.date4);
    },
    date5() {
      this.d_dateApproved = this.$_formatDate(this.date5);
    }
  },
  methods: {
    async initialize() {
      if (this.attendanceSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Provider List */
      if (this.attendanceSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
        this.fk_clientID = "";
      } else if (this.attendanceSection === 3) {
        /* Client List */
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_providerID = "";
        this.fk_clientID = client.id;
      } else {
        /* All list */
        this.fk_providerID = "";
        this.fk_clientID = "";
      }

      const user = this.$store.getters["usersStore/getAuthUser"];
      this.f_viewPrivateRecords = user.f_viewPrivateRecords;

      /* Grab filters */
      const filters = this.$store.getters["attendanceItemsStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("attendanceItemsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      await this.$store.dispatch("yearsStore/valueList");
      const vldata = {
        id: [10]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);
      await this.$store.dispatch("programsStore/valueList");
    },

    async filter(page) {
      this.filterMenu = false;
      this.selectedID = [];
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.fk_householdID = "";
      this.d_childIDv1 = "";
      this.d_prekSeatID = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.f_month = "";
      this.d_year = "";
      this.f_followup = "";
      this.f_status = "";
      this.noFollowup = "";
      this.selectedID = [];
      this.d_dateStart = "";
      this.d_dateEnd = "";
      this.date1 = "";
      this.date2 = "";
      this.fk_programID = "";
      this.fk_programOptionID = "";
      this.clientID = "";
      this.dateStart = "";
      this.dateEnd = "";
      this.d_daysAbsent = "";
      this.d_daysTotal = "";
      this.attendanceEntered = "";
      this.attendanceNotEntered = "";
      this.f_placeholder = "";
      this.fk_reportID = "";
      this.actualDatesStartEntered = "";
      this.actualDatesEndEntered = "";
      this.parentFeeNotCollected = "";
      this.d_dateApproved = "";
      this.page = 1;
      this.$store.dispatch("programsStore/clearOptionsValueList");
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.attendanceSection !== this.section && !this.fk_reportID) {
        this.clearFilterColumns();
        this.$store.dispatch("attendanceItemsStore/clearAttendanceItems");
      }
      /* Save section in store */
      this.section = this.attendanceSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_licenseNumber ||
        this.d_nameLicense ||
        this.d_nameFirst ||
        this.d_nameLast ||
        this.clientID ||
        this.fk_householdID ||
        this.d_childIDv1 ||
        this.d_prekSeatID ||
        this.f_month ||
        this.d_year ||
        this.f_followup ||
        this.f_status ||
        this.f_status === 0 ||
        this.f_placeholder ||
        this.f_placeholder === 0 ||
        this.noFollowup ||
        this.d_dateStart ||
        this.d_dateEnd ||
        this.fk_programID ||
        this.fk_programOptionID ||
        this.dateStart ||
        this.dateEnd ||
        this.d_daysAbsent ||
        this.d_daysTotal ||
        this.attendanceEntered ||
        this.attendanceNotEntered ||
        this.fk_reportID === 12 ||
        this.actualDatesStartEntered ||
        this.actualDatesEndEntered ||
        this.parentFeeNotCollected ||
        this.d_dateApproved
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id, privateRecord) {
      const user = this.$store.getters["usersStore/getAuthUser"];
      if (privateRecord === 0 || (privateRecord === 1 && user.f_viewPrivateRecords === 1)) {
        if (user.f_attendanceEdit === 1) {
          await this.loadValueLists();
          await this.$store.dispatch("attendanceItemsStore/entry", id);
        }
      } else {
        const data = {
          color: "error",
          text: "Access to this record is prohibited."
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    },

    selectDeselectAll() {
      this.$store.dispatch("attendanceItemsStore/selectDeselectAll");
    },

    async pullProgramOptions(id) {
      this.fk_programOptionID = "";
      const data = {
        fk_programID: id
      };
      await this.$store.dispatch("programsStore/valueListOptions", data);
    },

    checkTotalHighlight(present, absent, closed, total) {
      if ((present && total) || (absent && total) || (closed && total)) {
        if (Number(present) + Number(absent) + Number(closed) !== Number(total)) {
          return true;
        }
      }
    },

    detail(mode, uuid) {
      /* Provider */
      let routeData;
      if (mode === 1) {
        routeData = this.$router.resolve({ name: "provider", params: { providerUUID: uuid } });
      } else if (mode === 2) {
        /* Client */
        routeData = this.$router.resolve({ name: "client", params: { clientUUID: uuid } });
      }
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
td {
  border: none !important;
}
tbody {
  border-bottom: 1px solid #a9a9a9;
}
</style>
